import axios from 'axios'
// console.log(process.env.VUE_APP_BASE_API)
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000,
})

service.interceptors.request.use(
  (config) => {
    let authorization = ''

    if (localStorage.getItem('cbaUser')) {
      authorization = JSON.parse(localStorage.getItem('cbaUser')).authorization
    }
    config.headers.authorization = authorization
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

service.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
