import request from '@/utils/request'
/**
 * 封面列表
 */
export function getPlateListApi() {
  let res = request({
    url: '/apg/v1/plate/list',
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return res
}

/**
 * 精彩视频列表
 */
export function getVideoListApi() {
  let res = request({
    url: '/apg/v1/video/list',
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return res
}

/**
 * 系统通知列表
 */
export function getSystemListApi() {
  let res = request({
    url: '/apg/user/system/list',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return res
}

/**
 * 球迷征稿列表
 */
export function getArticleListApi() {
  let res = request({
    url: '/apg/v1/article/list',
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return res
}

/**
 * 亚运咨询
 */
export function getBasketListApi() {
  let res = request({
    url: '/apg/v1/basket/list',
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return res
}

/**
 * 热点管理列表
 */
export function getHotListApi() {
  let res = request({
    url: '/apg/v1/hot/list',
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return res
}

/**
 * 球迷征稿上传
 */
export function addUploadApi(params) {
  let res = request({
    url: '/apg/user/article/add',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: params,
  })
  return res
}

/**
 * 球迷征稿修改文章
 */
export function editArticleApi(params) {
  let res = request({
    url: '/apg/user/article/edit',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  })
  return res
}

/**
 * 我的投稿
 */
export function myContributeApi(params) {
  let res = request({
    url: '/apg/user/my/contribute',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  })
  return res
}

/**
 * 亚运文章详情
 * @param {*} params
 * @returns
 */
export function getBasketInfoApi(params) {
  let res = request({
    url: '/apg/v1/basket/info',
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  })
  return res
}

/**
 * 回忆详情
 */
export function getArticleInfoApi(params) {
  let res = request({
    url: '/apg/v1/article/info',
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  })
  return res
}

/**
 * cba热点详情
 */
export function getHotInfoApi(params) {
  let res = request({
    url: '/apg/v1/hot/info',
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  })
  return res
}


/**
 * token
 */
export function tokenInfoApi(params) {
  let res = request({
    url: '/apg/ddh/info',
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  })
  return res
}

