import { defineStore } from 'pinia'
import { getPlateListApi, getSystemListApi } from '@/api/user'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      plateListMap: {},
      systemList: [],
    }
  },
  getters: {
    dialogData: (state) => {
      return state.dialogOptions.data.content
    },
  },
  actions: {
    getPlateList() {
      return new Promise((resolve) => {
        getPlateListApi().then((data) => {
          const { code, result } = data
          if (code == 0) {
            result.forEach((item) => {
              this.plateListMap[item.type] = item
            })
            resolve(data)
          }
        })
      })
    },
    getSystemList() {
      return new Promise((resolve) => {
        getSystemListApi().then((data) => {
          const { code, result } = data
          if (code == 0) {
            this.systemList = result || []
            resolve(data)
          }
        })
      })
    },
  },
})
