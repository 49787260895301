import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)
import pinia from '@/stores/index'
import router from '@/router/index'
import 'vant/lib/index.css';
import 'amfe-flexible'
import '@/assets/css/global.scss'

app.use(pinia)
app.use(router)

app.mount('#app')
