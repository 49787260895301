import { createRouter, createWebHistory } from 'vue-router'
const router = createRouter({
  history: createWebHistory(), //路由模式
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/index/index.vue'),
    },
    {
      path: '/basketball-information',
      name: 'basketballInformation',
      component: () => import('../views/basketball-information/index.vue'),
    },
    {
      path: '/yayun-information',
      name: 'yayunInformation',
      component: () => import('../views/yayun-information/index.vue'),
    },
    {
      path: '/new-message',
      name: 'newMessage',
      component: () => import('../views/new-message/index.vue'),
    },
    {
      path: '/moments',
      name: 'moments',
      component: () => import('../views/moments/index.vue'),
    },
    {
      path: '/yayun-moments',
      name: 'yayunMoments',
      component: () => import('../views/yayun-moments/index.vue'),
    },
    {
      path: '/article-details/:id',
      name: 'articleDetails',
      component: () => import('../views/article-details/index.vue'),
    },
    {
      path: '/hot-details/:id',
      name: 'hotDetails',
      component: () => import('../views/hot-details/index.vue'),
    },
    {
      path: '/yayun-details/:id',
      name: 'yayunDetails',
      component: () => import('../views/yayun-details/index.vue'),
    },
    {
      path: '/cba-hotspots',
      name: 'cbaHotspots',
      component: () => import('../views/cba-hotspots/index.vue'),
    },
    {
      path: '/yayun-hotspots',
      name: 'yayunHotspots',
      component: () => import('../views/yayun-hotspots/index.vue'),
    },
    {
      path: '/fan-solicitation',
      name: 'fanSolicitation',
      component: () => import('../views/fan-solicitation/index.vue'),
    },
    {
      path: '/edit-active',
      name: 'editActive',
      component: () => import('../views/edit-active/index.vue'),
    },
  ],
})

export default router
