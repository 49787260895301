/* unplugin-vue-components disabled */import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { showToast } from 'vant';
import { tokenInfoApi } from '@/api/user';
import { useUserStore } from '@/stores/userStore';
export default {
  __name: 'App',
  setup(__props) {
    const userStore = useUserStore();
    const {
      getPlateList
    } = userStore;
    onMounted(() => {
      getPlateList();
      ready();
    });

    //获取大都会thirdCode
    // 由于机制问题，JSAPI注入是异步过程，时序无法保证。
    // 如果使用原生AlipayJSBridge接口，需要先监听AlipayJSBridgeReady事件后再调用原生JSAPI接口。
    const ready = callback => {
      // 如果jsbridge已经注入则直接调用
      if (window.AlipayJSBridge) {
        // showToast('获取用户信息')
        callback && callback();
      } else {
        // 如果没有注入则监听注入的事件
        document.addEventListener('AlipayJSBridgeReady', callback, false);
      }
    };
    ready(function () {
      window.AlipayJSBridge.call('metro_thirdPartyAuthToken', {
        thirdCode: 'urbanSportsCode'
      }, function (result) {
        // setTimeout(() => {
        //   showToast('监听')
        // }, 2000)
        // const { code } = result
        // setTimeout(() => {
        //   showToast(code)
        // }, 4000)
        const {
          code,
          data
        } = result;
        if (code == 1000) {
          getTokenInfo(data.token);
        }
      });
    });
    const getTokenInfo = async token => {
      const {
        code,
        result
      } = await tokenInfoApi({
        token: token
      });
      if (code == 0) {
        localStorage.setItem('cbaUser', JSON.stringify(result));
      }
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};